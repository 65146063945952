import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area Start
import {toast} from 'react-toastify';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  history?: any;
  location?: any;
  classes?: any;
  param?: "browse-all";
  loading?: boolean;
  activeTabColor?: string;
  id?:string;
  // onSearch: (searchText: string) => void;

}
interface FreelancerProjectListType{
  project_id: number | string,
  project_name: string,
}

interface MilestoneType {
  id: number;
  milestone_title: string;
  due_date: string;
  deposite_amount: null;
  milestone_status: string;
  project_id: number;
  created_at: string;
  updated_at: string;
  milestone_description: string;
  progress_percentage: number;
  revision_details: null | string;
  additional_bonus: null;
  another_amount: null;
  message_for_sponsor: null | string;
  payment: null;
  cost: string; 
  category_id: null;
  freelancer_id: number;
  is_delayed: boolean;
  approved: boolean;
  completed_date: null | string;
  category_milestone: boolean;
  start_date: string;
  end_date: string;
  total_cost: null | number; 
  exact_days_taken: null;
  days: null;
}

interface FreelancerProjectChartListType
  {
    id: number;
    name: string;
    expectedCompletedDate: null | string;
    exactCompletedDate: null | string;
    milestone: any;
    due_date: string;
    cost: string;
    milestone_status: string;
    start_date: string;
    end_date: string;
}
interface S {
  // Customizable Area Start
  showSimilar:boolean;
  similarProjectId:string;
  data: any;
  piedata: any;
  responseData: any[];
  filterType: string;
  bidStatus: string;
  dataName: any;
  open: boolean;
  notificationAnchor: any;
  bannerImage: any;
  currentPage: number;
  activeTab: number;
  searchText: string;
  queryString: string;
  projectList: any[];
  projectLoading: boolean;
  activeBidLoading: boolean,
  totalProjects: number;
  profileidstate:string;
  currentUser:any;
  isFavouriteProfileID: number;
  activeBidList: any;
  talentdata:any;
  userprofilecount : number;
  userFooterData: object[];
  projectListLoader:boolean;
  projectChartLoader:boolean;
  freelancerProjectListData:FreelancerProjectListType[];
  FreelancerProjectChartList:FreelancerProjectChartListType[];
  projectjId:string,
  chartValues:string;
  activeInProgressProject:any;
  freelancerProjectListError:string;
  userCountry: string;
  userCurrency: string;
  currentUserId:string;
  userType: string;
  termsAndServiceFooter:{
    loading:boolean;
    title:string;
    description:string;
    open:boolean;
},
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class FreelancerDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getprofileid:any;
  getAllprojectId: any;
  apiDashboardBannerItemCallId: any;
  getAllProjectApiCallId: any;
  addFavouritesProjectCardsApiCallId: string= "";
  getAllActiveBidListApiCallId: string = "";
  profileid: string = "";
  TalentDataID :string="";
  apiGetCurrentUserCallId:string=""
  getAllFreelancerProjectApiCallId: string=""
  getFreelancerProjectChartListApiCallId: string=""
  getActiveInProgressProjectApiCallId:string="";
  getTermsAndServiceApiCallId:string="";
  apiGetFooterDataCallId: string="";
  currentPage:string="Freelancer";
  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.LoginUserMessage),
    ];

    this.state = {
      // Customizable Area Start
      showSimilar:false,
      similarProjectId:"",
      activeBidList: [],
      isFavouriteProfileID: NaN,
      currentUser:null,
      profileidstate:"",
      projectList: [],
      projectLoading: false,
      activeBidLoading: false,
      totalProjects: 0,
      currentPage: 1,
      activeTab: 0,
      searchText: "",
      queryString: "",
      bidStatus: "",
      open: false,
      notificationAnchor: null,
      filterType: "",
      dataName: '',
      userType:"",
      responseData: [],
      data: [
        {
          name: "Jan",
          amt: 450,
          month: 20,
        },
        {
          name: "Feb",
          amt: 400,
          month: 100,
        },
        {
          name: "Mar",
          amt: 380,
          month: 180,
        },
        {
          name: "Apr",
          amt: 320,
          month: 205,
        },
        {
          name: "May",
          amt: 300,
          month: 460,
        },
        {
          name: "Jun",
          amt: 280,
          month: 105,
        },
        {
          name: "Jul",
          amt: 200,
          month: 355,
        },
        {
          name: "Aug",
          amt: 190,
          month: 40,
        },
        {
          name: "Sep",
          amt: 110,
          month: 50,
        },
        {
          name: "Oct",
          amt: 85,
          month: 180,
        },
        {
          name: "Nov",
          amt: 30,
          month: 105,
        },
        {
          name: "Dec",
          amt: 0,
          month: 20,
        }
      ],
      piedata: [{ name: "Drafts", value: 40 },
      { name: "Completed", value: 32 },
      { name: "In-Progress", value: 48 },
      { name: "Active", value: 72 }, { name: "Deleted", value: 48 }],

      bannerImage: [],
      talentdata:[],
      userprofilecount:0,
      userFooterData:[],
      projectListLoader:false,
      projectChartLoader:false,
      freelancerProjectListData:[],
      FreelancerProjectChartList:[],
      projectjId:"",
      chartValues:'',
      activeInProgressProject:null,
      freelancerProjectListError:"",
      userCountry: '',
      userCurrency: "",
      currentUserId: "",
      termsAndServiceFooter:{
        loading:false,
        title:"",
        description:"",
        open:false,
    },
      // Customizable Area End
    };


    runEngine.attachBuildingBlock(this, this.subScribedMessages);

  }
  async componentDidMount() {
    super.componentDidMount();
    this.prepareFinalQueryForSearch();
    this.getDashboardBanner();
    this.gettalentdata()
    this.getCurrentUser()
    this.getAllFreelancerProject();
    this.getActiveInProgressProject();
  }

  async receive(from: string, runEngineMessage: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", runEngineMessage);

    // Customizable Area Start
    if (runEngineMessage.id === getName(MessageEnum.LoginUserMessage)) {
      const user = runEngineMessage.getData(getName(MessageEnum.LoginUserBodyMessage));
       this.handleCurrentUserResponse(user);
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === runEngineMessage.id) {
      const apiRequestCallId = runEngineMessage.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = runEngineMessage.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleresponse(apiRequestCallId,responseJson)
      if (responseJson && apiRequestCallId === this.getprofileid) {
        responseJson.data &&
        this.setState({  currentUser:responseJson.data, profileidstate: responseJson.data.id ,},()=>{
          this.getAllActiveBidList(responseJson.data.id)
        })
       }
      else if(apiRequestCallId === this.addFavouritesProjectCardsApiCallId) {
        this.prepareFinalQueryForSearch();
      }
     if(apiRequestCallId===this.getTermsAndServiceApiCallId){
        this.handleTermsAndServicePayload(responseJson);
    }
    }
    // Customizable Area End
  }


  handleCurrentUserResponse=(user:any)=>{
    if(user?.id){
      this.setState({ 
          currentUser: user ,
          profileidstate: user.id 
        },()=>{
        this.getFooterData();
      });
    }

  }
  getAllProjectsDetails = (query: string) => {
    this.setState({
      projectLoading: true,
    });
    const token = localStorage.getItem("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `/bx_block_profile/projects/get_lancers_projects?${query}`,
      body: null,
      method: "GET",
    });

    this.getAllProjectApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  };



  getActiveInProgressProject=async()=>{
    const token =await getStorageData("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `bx_block_profile/projects/get_lancers_projects?current_page=1&per_page=1&order=desc&project_status=in-progress`,
      body: null,
      method: "GET",
    });

    this.getActiveInProgressProjectApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  }
  handleInProgressProjectApiResponse=(responseJson:any)=>{
     if(responseJson && responseJson?.projects?.data?.length>0){
       this.setState({activeInProgressProject:responseJson.projects.data[0]})
     }
  }
  inProgressProjectNavigate=(to:"progress"|"message")=>{
      const {activeInProgressProject}=this.state;
      if(to==="message"){
        const sponsorId=activeInProgressProject?.attributes?.sponsor_profile?.account_id || "";
        const projectId=activeInProgressProject?.attributes?.id;
        const createChatMessage: Message = new Message(
          getName(MessageEnum.NavigationMessage)
        );
        createChatMessage.addData(
          getName(MessageEnum.NavigationTargetMessage),
          "Chat"
        );
        createChatMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
         
        const raiseMessage: Message = new Message(
          getName(MessageEnum.NavigationPayLoadMessage)
        );
        raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
          createConversation:true,
          userId:sponsorId,
          projectId:projectId,
          userType:"sponsor"
        });
        createChatMessage.addData(
          getName(MessageEnum.NavigationRaiseMessage),
          raiseMessage
        );
        this.send(createChatMessage);
      }else{
         this.props.navigation?.navigate("PrjSummary",{
           projectid:activeInProgressProject?.id
         })
      }
  }
  getHeader=async()=>{
    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token
    };
    return header;
  }
  getTermOfServiceInfoFooter = async(terms:string, responseJson: any) => {
    if(responseJson?.terms_of_service?.description) {
        this.setState(prev=>({
            termsAndServiceFooter:{
                ...prev.termsAndServiceFooter,
                loading:true,
                open:true
            }
        }))
    }
    
    
    const header=await this.getHeader();
    const apiMessage=CreateRestApiMessage({
        apiUrl: `account_block/terms_of_services?title=${terms}`,
        method:"GET",
        body:null,
        header:header,
    })
    this.getTermsAndServiceApiCallId = apiMessage.messageId;
    runEngine.sendMessage(apiMessage.id, apiMessage);
  }
  navigateToHomePage=()=>{
    const pathName=window.location.pathname;
    const freelancerHomePath="FreelancerDashboard";
    const sponsorHomePath="SponsorDashboard";
    const homePages=[`/${freelancerHomePath}`,`/${sponsorHomePath}`];
    const currentPath=this.currentPage==="Freelancer"?freelancerHomePath:sponsorHomePath;
    if(homePages.includes(pathName)){
      window.scrollTo(0, 0)
      window.location.reload();
    }else{
      window.location.href=`/${currentPath}`;
    }
 }
  handleTermsAndServicePayload=(responseJson:any)=>{
    if(responseJson?.terms_of_service?.description) {
        this.setState({
            termsAndServiceFooter:{
                loading:false,
                title:responseJson?.terms_of_service?.title || "",
                description:responseJson?.terms_of_service?.description || "",
                open:true
            }
        })
    }else if (responseJson?.terms_of_service?.url){
        window.open(responseJson.terms_of_service.url, '_blank');
    }
        
}
  getActiveProjectProgress=()=>
  {
    const progressString = this.state.activeInProgressProject?.attributes?.No_of_milestone_completed || "";
    if(!progressString){
      return;
    }
    const progressArray = progressString.split(' / ');
    let currentProgress = parseInt(progressArray[0]?.trim());
    let totalGoal = parseInt(progressArray[1]?.trim());
    return (currentProgress / totalGoal) * 100;
    
  }
  handleCloseTermsAndServiceModal=(open:boolean)=>{
    this.setState((prev)=>({
        termsAndServiceFooter:{
            ...prev.termsAndServiceFooter,
            open:open
        }
    }))
}
  handleresponse = (apiRequestCallId: any, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.getAllProjectApiCallId:
        this.handleGetAllProjects(responseJson);
        break;
    
      case this.apiDashboardBannerItemCallId:
        this.setState({ bannerImage: responseJson.data });
        break;
    
      case this.getAllActiveBidListApiCallId:
        this.setState({ activeBidList: responseJson?.bids || [] });
        break;
    
      case this.TalentDataID:
        this.setState({ talentdata: responseJson.data });
        break;
    
      case this.apiGetCurrentUserCallId:
          if(responseJson.data){
            this.setState({ userprofilecount: responseJson.profile_completion_percentage, 
              userCountry: responseJson.data.attributes.country, userCurrency:responseJson.data.attributes.currency, userType: responseJson.data.attributes.role_name ,
            currentUserId: responseJson.data.id });    
            this.getAllActiveBidList(responseJson.data.id)
          }
        break;
        case this.apiGetFooterDataCallId:
          this.setState({ userFooterData: responseJson}, ()=> {
            
          });
          break;
        case this.getAllFreelancerProjectApiCallId:
          if(responseJson.data){
            const extractedChartData = [
                { project_id: "", project_name: "All Projects" },
              ...responseJson.data.map((item: any) => ({
                project_id: item.project_id.toString(),
                project_name: item.project_name,
              })),
            ];

            this.setState({
              freelancerProjectListData: extractedChartData,
              chartValues: "",
              projectListLoader:false
            });

         this.getFreelancerProjectChartList()
        }else if(typeof responseJson?.errors?.[0]==="string"){
          this.setState({freelancerProjectListError:responseJson?.errors?.[0]})
        }
          break;
          case this.getFreelancerProjectChartListApiCallId:
            if(responseJson){
              this.getChartListing(responseJson)
            }

            break;
        case this.getActiveInProgressProjectApiCallId:{
          this.handleInProgressProjectApiResponse(responseJson);
          break;
          
        }
      default:
        break;
    }
  }
  getChartListing = (resp: any) => {
    this.setState({projectChartLoader:false})
      const extractedData = resp?.map((item: FreelancerProjectChartListType) => {
        return {
        id: item.id,
        name: item.name,
        expectedCompletedDate: item.expectedCompletedDate,
        exactCompletedDate: item.exactCompletedDate,
        due_date: item.milestone.due_date,
        milestone_status: item.milestone.milestone_status,
        start_date: item.milestone.start_date,
        end_date: item.milestone.end_date,
      };
    });
  
    this.setState({ FreelancerProjectChartList: extractedData });
  };
  
  
  getCurrentUser = async () => {
    const userToken = localStorage.getItem("loginSuccessToken");
    const header = {
      "Content-Type": configJSON.jsonContentType,
      token: userToken,
    };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.getCurrentUserApiUrl}`,
      method: "GET",
      body: null,
    });
    this.apiGetCurrentUserCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getFooterData = async () => {
    const {currentUser}=this.state;

const userType=currentUser?.currentState?.toLowerCase().includes("sponsor")?"sponsor":"freelancer";
   

const userToken = localStorage.getItem("loginSuccessToken");
const header = {
  "Content-Type": configJSON.jsonContentType,
  token: userToken,
};
const requestMessage = CreateRestApiMessage({
  header,
  apiUrl: `${configJSON.getFooterDataApiUrl}?user_type=${userType}`,
  method: "GET",
  body: null,
});
this.apiGetFooterDataCallId = requestMessage.messageId;
runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getProfileId = () => {
    const token = localStorage.getItem("loginSuccessToken");
    const header = {
      token: token,
    };
    const getProfileIdRequest: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getprofileid = getProfileIdRequest.messageId;
    getProfileIdRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileIdEndpoint
    );
    getProfileIdRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    getProfileIdRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(getProfileIdRequest.id, getProfileIdRequest);
  };
  addFavouritesProjectCards = (isFavouriteProfileID:any) =>{
      const token = localStorage.getItem("loginSuccessToken");
      let body = {
        favourite:{
          project_id:isFavouriteProfileID
        } 
      }
      const header = {
        "Content-Type": "application/json",
        token: token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.addFavouritesProjectCardsApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_favourites/favourites`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        `POST`
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    };

    gettalentdata = () => {
      const token = localStorage.getItem("loginSuccessToken");
      const header = {
        token: token,
      };
      const apiRequestMessage = CreateRestApiMessage({
        header,
        apiUrl: `bx_block_profile/profiles/category_count`,
        body: null,
        method:`${configJSON.httpGetMethod}`,
      });
      this.TalentDataID = apiRequestMessage.messageId;
      runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
    };
    
    getAllActiveBidList = (id:any) => {
          const token = localStorage.getItem("loginSuccessToken");
      const header = {
        token: token,
      };
        const getValidationsMsg = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAllActiveBidListApiCallId = getValidationsMsg.messageId;
  
        getValidationsMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_auctionbidding/bids?profile_id=${id}&current_page=1&per_page=5`
        );
        getValidationsMsg.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          header
        );
  
        getValidationsMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpGetMethod
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
      }
  navigateToBrowseProject = () => {
    this.props?.navigation?.navigate("FreelancerProjectsLayout")
  }
  navigateToProfileSetup = () => {
    this.props?.navigation?.navigate("Customisableuserprofiles2")
  }
  navigateToMyBidsProject = () => {
    this.props?.navigation?.navigate("FreelancerProjects", { type: "my-bid" });
  }
  searchHandler = (searchText: any) => {
    this.setState({ currentPage: 1, searchText: searchText }, () => {
      this.prepareFinalQueryForSearch();
    });
  };
  getTabQueryValues = () => {
    const { activeTab } = this.state;
    if (activeTab === 0) {
      return "";
    } else if (activeTab === 1) {
      return `bid_status=open_bid_project`;
    } else if (activeTab === 2) {
      return `bid_status=non_bid_project`;
    }
  };
  handleGetAllProjects = (responseJson: any) => {
    if (responseJson && responseJson?.projects?.data) {

      this.setState((prev) => {
        let newProjectList: any[] = [];
        if (prev.currentPage === 1) {
          newProjectList = responseJson?.projects?.data;
        } else {
          newProjectList = [...prev.projectList, ...responseJson.projects.data];
        }
        return {
          totalProjects: responseJson?.pagination?.total_data || 0,
          projectLoading: false,
          projectList: newProjectList,
        };
      });
    } else {
      this.setState({ projectLoading: false,projectList:[] });
    }
  };
  handleSearchTextChange = async (e: any) => {
    this.setState({ searchText: e.target.value }, () => {
      this.prepareFinalQueryForSearch();
    });
  };


   trimtitle = (value:any) =>{
    if(value.length > 12){
        return value.slice(0,12) + "..."
    }
    else{
        return value
    }
}
  getProjectParamQuery = () => {
    const param = this.props.param;
    if (param === "browse-all") {
      return "";
    }
  };
  handleSearchTextKeyPressed = (e: any) => {
    if (e?.key === "Enter") {
      this.onSearchHandler();
    }
  };
  handleClearSearchText = () => {
    this.setState({ searchText: "" }, () => {
      this.prepareFinalQueryForSearch();
    });
  };
  onSearchHandler = () => {
    this.prepareFinalQueryForSearch()
  };
  navigateToBidProjectDetailsCards = (id: string) => {
    this.props?.navigation?.navigate("QuoteProjectDetails", { projectId: id })
  }
  navigateToSubmitBidPageCards = (id: string) => {
    const project = this.state.projectList.find(data=> data.id === id) || null;

    if(project){
      if(project.attributes.number_of_propsals < project.attributes.number_of_bidders) {
        return this.props?.navigation?.navigate("BidProject", { projectId: id })
      }
    }

    return toast.error("Quote limit has been exceeded");
  }
  onShowSimilar=(projectId:string)=>{
    this.setState({showSimilar:true,similarProjectId:projectId,projectList:[]},()=>{
       this.prepareFinalQueryForSearch();
    })
}
  prepareFinalQueryForSearch = () => {
    let finalQuery: string[] = [];
    const { searchText, queryString, currentPage,showSimilar, similarProjectId} = this.state;
    finalQuery.push(`current_page=${currentPage}`);

    if(showSimilar){
      finalQuery.push(`show_similar=true`);
      finalQuery.push(`project_id=${similarProjectId}`);
    }
    if (searchText) {
      finalQuery.push(`search=${searchText}`);
    }
    if (queryString) {
      finalQuery.push(queryString);
    }
    const tabQuery = this.getTabQueryValues();
    if (tabQuery) {
      finalQuery.push(tabQuery);
    }
    const pageQuery = this.getProjectParamQuery();
    if (pageQuery) {
      finalQuery.push(pageQuery);
    }
    const newQueryText = finalQuery.join("&");
    this.getAllProjectsDetails(newQueryText);
  };
  handleTabChange = (e: any, newValue: number) => {
    const { activeTab } = this.state;
    if (activeTab !== newValue) {
      this.setState(
        { activeTab: newValue, currentPage: 1, projectList: [] },
        () => {
          this.prepareFinalQueryForSearch();
        }
      );
    }
  };
  getTabButtonValue = () => {
    const { activeTab } = this.state;
    if (activeTab === 0) {
      return "all";
    } else if (activeTab === 1) {
      return "bid-open";
    } else if (activeTab === 2) {
      return "fixed";
    } else if (activeTab === 3) {
      return "non-bid";
    }
  };

  // Customizable Area Start
  handlePopup = (e: any) => {
    this.setState({
      open: !this.state.open,
      notificationAnchor: e?.currentTarget,
    })
  }
  ClosePostmodal = () => {
    this.setState({ open: false, notificationAnchor: null })
  };
//---------------------Get all freelancer project ----------------------------------------//
getAllFreelancerProject = async() => {
   this.setState({projectListLoader:true})
  const token =   await getStorageData('loginSuccessToken', false)
  const header = {
    token: token,
    "Content-Type": configJSON.dashboarContentType,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getAllFreelancerProjectApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getFreelancerProjectsApiCallUrl
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.dashboarApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};
//-------------------- freelancer projects ends -----------------------------//
//---------------------Get all freelancer project ----------------------------------------//
getFreelancerProjectChartList = async() => {
 this.setState({projectChartLoader:true})
  const token =   await getStorageData('loginSuccessToken', false)
  const header = {
    token: token,
    "Content-Type": configJSON.dashboarContentType,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getFreelancerProjectChartListApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getFreelancerProjectsChartListApiCallUrl + `${this.state.chartValues}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.dashboarApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};
//-------------------- freelancer projects ends -----------------------------//
handleChartValueChange=(event: any) => {
this.setState({ chartValues: event.target.value })
this.getFreelancerProjectChartList()
}
truncateText = (text:string) => {
  return text.length > 12 ? text.substring(0, 12) + '...' : text;
};
handleProjectDetailNavigation = (id:string) => {
  this.props.navigation.navigate("QuoteDetails", { bidId:id});
};
  // Customizable Area End
  getDashboardBanner = async () => {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("loginSuccessToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardBannerItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardBannerGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }
  navigateToBrowseAllProjectPageWithCategory=(id:string)=>{
    this.props.navigation?.history?.push(`/FreelancerProjects/browse-all?c=${id}`)
  }
  getNavigation=(id:string)=>{
    return ()=>this.navigateToBrowseAllProjectPageWithCategory(id);
  }
}
