import React from "react";
//@ts-nocheck

import {
  // Customizable Area Start
  Grid,
  Box,
  Typography,
  Button,
  Card,
  Avatar,
  Divider,
  Tabs,
  Tab,
  FormHelperText,
  FormControl,
  TextField,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Modal,
  IconButton,
  RadioGroup,
  Radio,
  FormLabel,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import {
  KeyboardBackspace as KeyboardBackspaceIcon,
  MoreVert as MoreVertIcon,
  LocationOn as LocationOnIcon,
  QueryBuilder as QueryBuilderIcon,
  Error as ErrorIcon,
  CancelOutlined as CancelOutlined,
  CloseSharp as CloseSharp,
  EditOutlined, Add as AddIcon
} from "@material-ui/icons";
import {
  shareIcon,
  messageIcon,
  profilePhoto,
  wallet,
  calender,
  document,
  shareFile,
  download,
  reciept,
  note,
  revisionIcon,
  deleteFile,
} from "./assets";
import Dropzone from "react-dropzone";
import { downloadIcon } from "../../projecttemplates/src/assets";
import CircularProgress from "@material-ui/core/CircularProgress";
import InfoIcon from '@material-ui/icons/Info';
import ReceiptIcon from '@material-ui/icons/Receipt';
import moment from 'moment';



const tabsMenuList = [
  { id: 0, label: "Projects Summary" },
  { id: 1, label: "Projects Details" },
  { id: 2, label: "Messages" },
];
const StyledRadioButton = withStyles({
  root: {
    color: "#e4e6e8",
    '&$checked': {
      color: "#00A7A2",
    },
  },
  checked: {},
})(Radio);
const StyledTimelineItem = withStyles({
  missingOppositeContent: {
    "&:before": {
      display: "none"
    }
  }
})(TimelineItem);

const CustomCheckbox = withStyles({
  root: {
    path: {
      color: "#fff",
      border: "1.5px solid #E4E6E8",
      background: "rgba(245, 245, 250, 0.70)",
    },
    "&$checked": {
      color: "#00A7A2",
    },
  },
})(
  ({
    ...props
  }: {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checked: boolean;
    name: string;
    classes: {};
  }) => <Checkbox color="default" {...props} />
);


// Customizable Area End

import SchedulingController, {
  Milestone,
  Props,
  configJSON,
} from "./SchedulingController";
import ProjectDetails, { ProjectDetailsPageStyles } from "./ProjectDetails.web";
import MilestoneItem from "../../../components/src/utill/MilestoneItem.web";
import { ProjectHeaderDetails } from "../../../components/src/ProjectHeaderDetails.web";

export default class Scheduling extends SchedulingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  TabStyles = (index: number) =>
    this.state.selectedTab === index
      ? this.props.classes.activeTabStyle
      : this.props.classes.tabStyle;

  renderPaymnetApproved = (classes: { [key: string]: string }) => (
    <>
      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth className={classes.formControlStyle}>
            <FormHelperText className={classes.labelStyle} component="p">
              {configJSON.releaseFromEscrow}
            </FormHelperText>
            <TextField
              name="amountReleased"
              onChange={(event) => this.handleOnChange(event)}
              value={this.state.amountReleased}
              InputProps={{
                classes: {
                  input: classes.placeholderStyle,
                  root: classes.inputValueStyle,
                },
              }}
              placeholder={configJSON.releaseFromEscrow}
            />
          </FormControl>
        </Grid>
        {this.state.isAdditionalBonusAdded && (
          <Grid item xs={6}>
            <FormControl fullWidth className={classes.formControlStyle}>
              <FormHelperText className={classes.labelStyle} component="p">
                {configJSON.additionalBonus}
              </FormHelperText>
              <TextField
                name="additionalBonus"
                onChange={(event) => this.handleOnChange(event)}
                value={this.state.additionalBonus}
                placeholder={configJSON.additionalBonusPlaceholder}
                InputProps={{
                  classes: {
                    input: classes.placeholderStyle,
                    root: classes.inputValueStyle,
                  },
                }}
              />
            </FormControl>
          </Grid>
        )}
      </Grid>
      <FormControlLabel
        control={
          <CustomCheckbox
            checked={this.state.isAdditionalBonusAdded}
            onChange={this.addAdditionalBonus}
            name="checkedG"
            data-testid='additionalBonus'
          />
        }
        label={
          <Typography className={classes.labelStyle}>
            {configJSON.additionalBonus}{' '}
            <Box component="span" className={classes.subTitleStyle}>
              ({configJSON.optionalLabel})
            </Box>
          </Typography>
        }
      />
      <Box className={classes.payBtnContainer}>
        <Button
          className={classes.onHoldBtnStyle}
          onClick={this.handleApprovePayment}
        >
          {configJSON.cancelBtnTxt}
        </Button>
        <Button className={classes.closeBtnSyle}>{configJSON.payLabel}</Button>
      </Box>
    </>
  );

  handleButtonText = (text: string) => {

    return (this.state.loading ? <CircularProgress size={24} /> : text)
  }
  renderEditMilestone = (milestoneStatus: string, index: number) => {
    const classes = this.props.classes;
    return (
      <StyledTimelineItem >
        <TimelineSeparator>
          <TimelineDot className={classes.timelineDotStyle}
            style={milestoneStatus !== 'pending' ? { background: "#00A7A2" } : { background: '#8083a3' }}>
            {index + 1}
          </TimelineDot>
          <TimelineConnector
            className={classes.timelineConectorStyle}
            style={milestoneStatus !== 'pending' ? { border: "1px dashed #00A7A2", } : { border: "1px dashed #8083a3" }}
          />
        </TimelineSeparator>
        <Box style={{ width: "100%", marginLeft: "20px" }}>
          <MilestoneItem
            value={this.state.editMileston}
            changeValue={this.handleChangeEditMilestone}
            acceptedHourlyCost={this.state.projectInfo?.attributes?.accepted_amount || 0}
            onSubmit={this.handleMilestoneSubmit}
            onCancel={this.handleMilestoneCancel}
            saveLoading={this.state.saveMilestoneLoading}
          />
        </Box>
      </StyledTimelineItem>
    )
  }
  renderCreateNewMilestones = () => {
    const isInProgressProject=this.state.projectInfo?.attributes?.project_status===0;
    return (
      <Box>
        {
          !this.state.createNewMileston && this.state.projectInfo?.attributes?.budget_type !== "fixed_price" && isInProgressProject && (
            <Button data-testid="newMilestoneButton" onClick={this.handleCreateNewMilestone} startIcon={<AddIcon />}>
              Add New Milestone
            </Button>
          )
        }
        {
          this.state.createNewMileston && this.state.editMileston && (
            <MilestoneItem
              value={this.state.editMileston}
              changeValue={this.handleChangeEditMilestone}
              acceptedHourlyCost={this.state.projectInfo?.attributes?.accepted_amount || 0}
              onSubmit={this.handleMilestoneSubmit}
              onCancel={this.handleMilestoneCancel}
              newMilestone={true}
              saveLoading={this.state.saveMilestoneLoading}
            />
          )
        }

      </Box>
    )
  }

  renderMilestoneTimeLine = (milestone: Milestone, index: number) => {
    const classes = this.props.classes;
    const milestoneStatus = milestone.attributes.milestone_status
    return (
      <TimelineSeparator>
        {milestone.attributes.is_delayed ?
          <>
            <TimelineDot className={classes.timelineDotStyle}
              style={{ background: '#d90000' }}>
              {milestoneStatus === "canceled" ? "x" : index + 1}
            </TimelineDot>
            
          </>
          : <>
            <TimelineDot className={classes.timelineDotStyle}
              style={this.getTimelineDotStyle(milestoneStatus)}>
              {milestoneStatus === "canceled" ? "x" : index + 1}
            </TimelineDot>
          </>}
          {this.hideTimelineConnector(milestoneStatus,index) &&(
            <TimelineConnector
            
            style={this.getTimelineConnectorStyle(milestoneStatus)}
          />
          )}
      </TimelineSeparator>
    )
  }
  renderDownloadInvoiceButtons=(renderInvoice:boolean,renderReceipt:boolean,milestone:any)=>{
    const { classes } = this.props;
    const downloadOptions=this.state.download;
      return(
        <>
                 {renderInvoice && (<Button
                      style={webStyle.downloadGrid}
                      onClick={this.handleInvoiceDownload(milestone)}
                      data-testid="downloadInvoice"
                      disabled={downloadOptions.invoiceDownloadLoading}
                      
                    >
                      {
                        downloadOptions.invoiceDownloadLoading && downloadOptions.invoiceDownloadMilestoneId===milestone?.id ? (
                          <CircularProgress size={15}/>
                        ):(
                          <>
                              <ReceiptIcon style={{ fontSize: '18px' }} />
                              <span>{configJSON.downloadInvoice}</span>
                          </>
                        )
                      }
                     
                    </Button>
                   )
                  }
                  {
                    renderReceipt && (
                      <Button
                          style={webStyle.downloadGrid}
                          onClick={this.handleReceiptDownload(milestone)}
                          data-testid="downloadReceipt"
                          disabled={this.state.download.receiptDownloadLoading}
                        >
                          {
                            downloadOptions.receiptDownloadLoading && downloadOptions.receiptDownloadMilestoneId===milestone?.id ? (
                              <CircularProgress size={15}/>
                            ):(
                              <>
                                <ReceiptIcon style={{ fontSize: '18px' }} />
                                <span>Download Receipt</span>
                              </>
                            )
                          }
                          
                        </Button>
                    )
                  }
                    
        </>
      )
  }
  renderRevisonFiles=(revisionFile:any[])=>{
    const { classes } = this.props;
    return(
      <>
        {revisionFile && revisionFile.length>0 && (
          <Grid
            container
            spacing={2}>
              {
                this.state.revisionFile.map((file:File,index:number)=>(
                  <Grid item xs={12} sm={6}>
                      <Box className={classes.downloadItemBorder}>
                        <Box style={webStyle.downloadItemBox} className="resDownloadItem">
                          <img className={classes.milestoneGridImage} src={document} />
                          <Typography className={classes.downloadfileName}>{this.convertFileName(file.name)}</Typography>
                          <Typography className={classes.downloadfileSize}>{this.onConvertFileSize(file.size)}</Typography>
                        </Box>
                        <Box className={classes.iconsBox}>

                        <img
                          src={deleteFile}
                          className={classes.deleteIconStyle1}
                          alt="download1"
                          onClick={this.handleDeleteUploadedFile.bind(this,index)}
                          data-testId="deleteUploadedFile"
                        />
                      </Box>

                    </Box>
                  </Grid>
                ))
              }
          


        </Grid>)}
      </>
    )
  }
  renderCompletedAndFileSubmited = (milestone: any) => {
    const { classes } = this.props;
    const milestoneStatus = milestone.attributes.milestone_status
    const validationPeriod = milestone.attributes.period_status
    const coolingPeriod = milestone.attributes.cooling_period_status
    const refundStatus = milestone.attributes.milestone_refund_status
    const refundDisputeStatus = milestone.attributes.refund_dispute_status 
    return (
      <>
        {this.getActivePeriod(validationPeriod,coolingPeriod) &&
          <>
            <Divider className={classes.divider}></Divider>
            <Box style={webStyle.validationStyle} className="resValidationStyle">
              {validationPeriod == configJSON.periodStatus &&
                <>
                  <Box display="flex">
                    <img alt="duedate_img" className={classes.milestoneGridImage} src={calender} />
                    <Typography className={classes.timelineContentDesc}>
                      <span className={`${classes.labelStyle} ${classes.marginStyle}`}>{configJSON.labelAcceptanceEnd}</span>
                      {moment(milestone.attributes.validation_date).format(configJSON.dueDateFormat)}
                    </Typography>
                  </Box>
                </>
              }
              {coolingPeriod == configJSON.periodStatus && <>
                <Box display="flex">
                  <img alt="duedate_img" className={classes.milestoneGridImage} src={calender} />
                  <Typography className={classes.timelineContentDesc}>
                    <span className={`${classes.labelStyle} ${classes.marginStyle}`}>{configJSON.labelCoolingEnd}</span>
                    {moment(milestone.attributes.cooling_end_date).format(configJSON.dueDateFormat)}
                  </Typography>
                </Box>
                <Box marginLeft={"auto"}>
                    <Button
                      className={classes.closeBtnSyle}
                      style={{ padding: "2px 15px", fontWeight: 600 }}
                      data-test-id={`refundRequest${milestone.id}`}
                      onClick={() => this.handleRefund(milestone.id)}
                      variant="contained"
                      disabled={refundStatus}
                      >
                      {configJSON.refund}
                    </Button>
                  
                </Box>

              </>
              }
            </Box>
          </>

        }
        <Divider className={classes.divider} />
        <Box className={classes.filesBox}>
          <Box className={classes.reviewSubmitFiles}>
            <img src={note} alt="files" />
            <Typography
              className={classes.reviewFilesLabel}
            >
              {configJSON.submittedFilesLabelTxt}
            </Typography>
          </Box>

          <Grid
            container
            spacing={2}>
            {milestone.attributes.documents?.map((doc: any) => (
              <Grid item xs={12} sm={6} md={6} key={doc.blob_id}>
                <Box className={classes.downloadItemBorder}>
                  <Box style={webStyle.downloadItemBox} className="resDownloadItem">
                    <img className={classes.milestoneGridImage} src={document} />
                    <Typography className={classes.downloadfileName}>{this.convertFileName(doc.name)}</Typography>
                    <Typography className={classes.downloadfileSize}>{this.onConvertFileSize(doc.size)}</Typography>
                  </Box>
                  <Box className={classes.iconsBox}>
                    <img
                      src={download}
                      className={classes.deleteIconStyle1}
                      alt="download1"
                      onClick={this.handleDownloadFile.bind(this, doc.url, doc.name,"","")}
                    />
                  </Box>
                </Box>
              </Grid>
            ))
            }
          </Grid>

        </Box>
        <Divider style={{ marginTop: "10px" }} className={classes.divider} />
        {this.state.revisionDetailsFormId === milestone.attributes.id ? (
          <Box className={classes.requestDetailsContainer}>
            <Box className={classes.reviewSubmitFiles}>
              <img src={revisionIcon} alt="files" />
              <Typography
                className={classes.reviewFilesLabel}
              >
                {configJSON.revisionRequestLabelTxt}
              </Typography>
            </Box>

            <Box className={classes.paymentContainer}>
              <FormControl
                fullWidth
                style={webStyle.formControlStyle}
              >
                <FormHelperText
                  style={webStyle.labelStyle}
                  component="p"
                >
                  {configJSON.requestDetails}
                </FormHelperText>
                <TextField
                  minRows={3}
                  multiline
                  data-testid="revisonDetails"
                  name="revisonDetails"
                  onChange={(event) =>
                    this.handleOnChange(event)
                  }
                  value={this.state.revisonDetails}
                  placeholder={
                    configJSON.requestDetailsDescription
                  }
                  InputProps={{
                    classes: {
                      input: classes.placeholderStyle,
                      root: classes.inputValueStyle,
                    },
                  }}
                />
              </FormControl>

                <Dropzone
                  onDrop={this.handleFileDrop}
                  data-testid="dropzone"
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="container">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Box
                          className={classes.dropZoneStyle}
                        >                                  
                          <Typography
                            className={classes.uploadFileTxt}
                          >
                            {configJSON.uploadFileTxt}
                          </Typography>
                          <Typography
                            className={classes.maxFileSizeTxt}
                          >
                            {configJSON.maxFileSizeTxt}
                          </Typography>
                        </Box>
                      </div>
                    </section>
                  )}
                </Dropzone>
                 {this.renderRevisonFiles(this.state.revisionFile)}
              <Box
                className={`${classes.sendContainerStyle} ${classes.btnContainerStyle}`}
              >
                <Button
                  className={classes.onHoldBtnStyle}
                  onClick={this.handleCancelRevisionScreen}
                >
                  {configJSON.cancelBtnTxt}
                </Button>
                <Button
                  className={classes.closeBtnSyle}
                  onClick={() => this.validateRevisionRequest(milestone.attributes.id)}
                  data-testId="revision-submit">
                  {this.handleButtonText(configJSON.sendBtnTxt)}
                </Button>
              </Box>
            </Box>
          </Box>
        ) : (
          <>
            <Box className={classes.reviewSubmitFiles}>
              <img src={reciept} alt="files" />
              <Typography
                className={classes.reviewFilesLabel}
              >
                {configJSON.paymentLabelTxt}
              </Typography>
            </Box>
            <Grid
              container
              spacing={2}
              className={classes.paymentContainer}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                className={classes.paymentStatus}
              >
                {configJSON.paymentStatusLabel}
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={9}
                className={classes.paymentValue}
              >
                {
                  milestoneStatus === "completed" ? <span style={{ color: "green" }}>
                    Paid
                  </span> : this.state.paymentStatus

                }
              </Grid>
              {milestoneStatus === "file_submitted" && (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    className={classes.paymentStatus}
                  >
                    {configJSON.AmountinEscrowLabelTxt}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                sm={6}
                md={9}
                    className={classes.paymentValue}
                  >
                    {this.renderEscrowAmountMultiCurrency(this.state.projectInfo?.attributes?.currency_conversion)}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                sm={6}
                md={3}
                    className={classes.paymentStatus}
                  >
                    {configJSON.originalAmountLabeltxt}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                sm={6}
                md={9}
                    className={classes.paymentValue}
                  >
                    {this.renderMultiCurrencyInfo(milestone)}
                  </Grid>
                </>
              )}

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                className={classes.paymentStatus}
              >
                {configJSON.requestedAmountLabeltxt}
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={9}
                className={classes.paymentValue}
              >
                {this.renderMultiCurrencyInfo(milestone)}
              </Grid>
              {(milestoneStatus === "refund_rejected" || milestoneStatus === "completed" )&& (
                <>
                  <Grid
                    item
                    xs={12}
                sm={6}
                md={3}
                    className={classes.paymentStatus}
                  >
                    {configJSON.releasedAmountLabeltxt}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                sm={6}
                md={9}
                    className={classes.paymentValue}
                  >
                    {this.renderMultiCurrencyInfo(milestone)}
                  </Grid>
                  <Grid
                    item
                    data-testid="downloadInvoice"
                    xs={12}
                    style={webStyle.downloadGrid}
                    className="resDownloadGrid"
                  >
                    {this.renderDownloadInvoiceButtons(true,true,milestone)}
                  </Grid>
                </>
              )}

              {/* release amount block */}
              {this.state.paymentFormId === milestone.attributes.id &&
                this.renderPaymnetApproved(classes)}
            </Grid>
            {(refundStatus === "reject" && refundDisputeStatus  == null ) && (
              <Box mt={3} mb={1}>
                <Button
                  className={classes.closeBtnSyle}
                  data-test-id="raiseDispute"
                  style={{textTransform:"none"}}
                  onClick={()=>this.props.navigation.navigate("Disputes",{projectId:this.state.projectId,milestoneId:milestone.attributes.id})}
                >
                  Raise a Dispute
                </Button>
              </Box>
            )}

            {/* paymentApproved */}
            {!this.state.isPaymentApproved && milestoneStatus === 'file_submitted' && (
              <>
                <Box mt={3} mb={1} className={classes.btnContainerStyle}>
                  <Button
                    style={webStyle.closeBtnSyle}
                    onClick={() => this.approvePayment(milestone.attributes.id, milestone?.attributes?.paid_status)}
                    data-testid="paymentApproved"
                    disabled={this.state.loaderId == +(milestone.id) }
                    className="resCloseBtn"
                  >
                    {this.state.loaderId == +(milestone.id) ? <CircularProgress size={24} /> : configJSON.approveBtnTxt}
                  </Button>
                  <Button
                    style={webStyle.onHoldBtnStyle}
                    onClick={() => this.setState({ revisionDetailsFormId: milestone.attributes.id })}
                    data-testid="revision-button"
                    className="resRevisionBtn"
                  >
                    {configJSON.requestRevisionBtnTxt}
                  </Button>
                  <div className="resDownloadBtn">{this.renderDownloadInvoiceButtons(true,false,milestone)}</div>
                </Box>
                <Box className={classes.reviewSubmitFiles}>
                  <ErrorIcon fontSize="small" />
                  <Typography
                    className={
                      classes.revesionDeadlineTxtStyle
                    }
                  >
                    {configJSON.revisonDeadlineTxt}
                  </Typography>
                </Box>
              </>
            )}
          </>
        )}
      </>

    )
  }  

  renderMilestones = (classes: { [key: string]: string }) => (
    <div className="demo">
      {this.state.allMilestones.map((milestone, index) => {
        const milestoneStatus = milestone.attributes.milestone_status
        const milestoneDelay = milestone.attributes.is_delayed;
        if (milestone?.edit) {
          return this.renderEditMilestone(milestoneStatus, index)
        }
        return (
          <StyledTimelineItem key={milestone.id}>
            {this.renderMilestoneTimeLine(milestone, index)}
            <TimelineContent>
              <Box style={webStyle.displayBox} className="resDisplayBox">
                <Box display="flex" alignItems="center">
                  <Typography className={classes.timelineContentHeader}
                    style={milestoneDelay ? { color: '#d90000', } : { color: "#222222" }}>
                    {milestone.attributes.milestone_title} 
                    {milestoneStatus === "pending" &&
                      this.state.projectInfo?.attributes?.budget_type !== "fixed_price"
                      && <IconButton data-testid="editMilestoneButton" onClick={() => this.handleEditMilestone(milestone, true)}><EditOutlined /></IconButton>}
                  </Typography>
                  {milestone.attributes.is_delayed &&
                    <>
                      <Tooltip enterTouchDelay={0} leaveTouchDelay={3000} title={`${milestone.attributes.milestone_title} is behind the scheduled time`} arrow placement='top'>
                        <InfoIcon className={classes.infoIconStyle} />
                      </Tooltip>
                    </>}
                </Box>
                <Typography
                  className={classes.timelineContentPendingBtn}
                  style={this.mileStoneStatusStyle(milestoneStatus)}
                >
                  {this.getMileStoneStatus(milestoneStatus)}
                </Typography>
              </Box>
              <Grid
                container
                className={classes.displayGrid}
                id="milestone"
              >
                {milestoneStatus !== "canceled" && milestoneStatus !== "on_hold" && (
                  <>
                    <Typography className={classes.timelineContentDesc}>
                      {milestone.attributes.milestone_description}
                    </Typography>
                    <Divider className={classes.divider} />
                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", flexWrap: "wrap", gap:"10px"  }}>
                      <Box className={classes.displayVerifyBox}>
                        <Box display="flex" alignItems="center">
                          <img
                            className={classes.milestoneGridImage}
                            src={calender}
                          />
                          <Typography
                            className={`${classes.dueDateStyle} ${classes.timelineContentDesc}`}
                          >
                            {milestone.attributes.due_date}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <img
                            className={classes.milestoneGridImage1}
                            src={wallet}
                          />
                          <Typography
                            className={`${classes.paymentStyle} ${classes.timelineContentDesc}`}
                          >
                            {this.renderMultiCurrencyInfo(milestone)}

                          </Typography>
                        </Box>
                        <Button
                          variant="contained"
                          disableElevation
                          disableRipple
                          className={classes.timelineContentVerifyBtn1}
                        >
                          {configJSON.labelVerify}
                        </Button>
                      </Box>
                     
                      {milestoneStatus === "pending" && (
                        <Button
                        variant="contained"
                          className={classes.closeBtnSyle}
                          style={{ padding: "2px 13px", alignSelf: "flex-end",fontWeight:600 }}
                          onClick={() => this.handlePayForMilestone(milestone.id)}
                          data-test-id={`pending`}
                          disabled={this.state.firstPendingMilestone != milestone.attributes.id}>
                          {configJSON.payAndStartMilestone}
                        </Button>
                      )}
                    </Box>
                    {
                      milestoneStatus==="refund" && (
                          <>
                            <Divider className={classes.divider}></Divider>
                            <Button
                                    className={classes.closeBtnSyle}
                                    style={{ padding: "2px 15px", fontWeight: 600 }}
                                    data-test-id={`withdraw_${milestone.id}`}
                                    onClick={() => this.navigateToWithdrawMilestone(milestone.id)}
                                    variant="contained"
                                    disabled={!milestone?.attributes?.withdrawal_ready && milestoneStatus==="refund"}
                                    >
                                    {milestone?.attributes?.withdrawal_ready?configJSON.withdrawRefund:configJSON.refundWithdrawn}
                                  </Button>
                          </>
                        )
                      }
                  </>
                )}
                {(milestoneStatus === "refund_rejected"  || milestoneStatus === "completed" || milestoneStatus === "file_submitted") && this.renderCompletedAndFileSubmited(milestone)}
              </Grid>
            </TimelineContent>
          </StyledTimelineItem>
        );
      })}
      {
        this.renderCreateNewMilestones()
      }
    </div>
  )

  renderEscrowAmountMultiCurrency=(conversion:{
        sponsor: {
            currency: string,
            currency_symbol:  string,
            escrow_amount: number
        }
  })=>{
    const sponsorConversion=conversion.sponsor;
    return  <>
     {sponsorConversion.currency_symbol}{sponsorConversion.escrow_amount} 
    </>
  }
  renderMultiCurrencyInfo=(milestone:{
    attributes:{
      currency_conversion?: {
        sponsor: {
            currency: string,
            currency_symbol:  string,
            amount: number
        },
        freelancer: {
            currency:  string,
            currency_symbol:  string,
            amount: number,
        }
     }
    }
  })=>{
    const{classes}=this.props;
    return  <>
    {milestone.attributes.currency_conversion?.freelancer?.currency_symbol}{milestone.attributes.currency_conversion?.freelancer?.amount} 
    {
      milestone.attributes.currency_conversion?.sponsor?.currency !== milestone.attributes.currency_conversion?.freelancer?.currency &&
      (
        <>
        <span data-testid="currencyConversion" className={classes.sponsorCurrencyColor}>&nbsp;({milestone.attributes.currency_conversion?.sponsor?.currency_symbol}{milestone.attributes.currency_conversion?.sponsor?.amount})</span>
        <Tooltip enterTouchDelay={0} leaveTouchDelay={3000} data-testid="currencyConversionTooltip" title={`${configJSON.currencyConvertionText1} (${milestone.attributes.currency_conversion?.freelancer?.currency}) ${configJSON.currencyConvertionText2} (${milestone.attributes.currency_conversion?.sponsor?.currency}) ${configJSON.currencyConvertionText3}`} arrow placement='top'>
        <InfoIcon className={classes.currencyIconStyle}/>
        </Tooltip>
        </>
      )
    }
    </>
  }
  getModalHeading = ()=>{
    const {updateStatus} = this.state

    if(updateStatus === "closed"){
      return configJSON.modalHeading
    }
    else if(updateStatus === "refund"){
      return configJSON.requestForRefund
    }
    else{
      return configJSON.putProjectOnHold
    }
  }

  getOptionsLabel = ()=>{
    const {updateStatus} = this.state

    if(updateStatus === "closed"){
      return configJSON.reasonHeading
    }
    else if(updateStatus === "refund"){
      return configJSON.refundOptionsLabel
    }
    else {
      return configJSON.refundOptionsLabel
    }
  }

  mileStoneStatusStyle = (status: string) => {

    if (status === 'pending' || status === "requested_for_revision") {
      return { backgroundColor: "#F7AD00",padding:"0 12px"}
    }
    else if (status === "started") {
      return ({ backgroundColor: "#3C98DC", padding: "0 12px" })
    }
    else if (status==="canceled"){
      return ({ backgroundColor: "#d90000", padding: "0 8px",width:"105px"})
    }
    else if(status === "on_hold"){
      return ({ backgroundColor: "#D9B90B", padding: "0 12px" })
    }
    else if(status === "refund"|| status === "refund_rejected"){
      return({backgroundColor:'#B8BBD6',padding:"0 12px"})
    }
    else {
      return ({ backgroundColor: " #16a700",borderRadius: "15px",width:"105px" })
    }
  }
  getMileStoneStatus = (title: string) => {
    switch (title) {
      case 'pending':
        return 'Pending';
      case "started":
        return "Started"
      case 'completed':
        return 'Completed';
      case 'requested_for_revision':
        return 'Requested for Revision';
      case 'file_submitted':
        return 'File Submitted';
      case "canceled":
        return "Milestone Canceled"
        case "on_hold":
        return "On-Hold"
        case "refund" :
          return "Refund Accepted"
          case "refund_rejected" :
            return "Refund Rejected"
      default:
        return '';
    }
  }

  getTimelineDotStyle = (status:string)=>{

    if(status === "pending"|| status === "started" || status === "on_hold"|| status === "refund" || status === "refund_rejected"){
      return { background: '#f2f2f2',color:"#222222" }
    }

    else if(status === "canceled"){
      return { background: "#d90000",fontSize:"22px"}
    }
    else{
      return {background:"#00A7A2"}
    }
  }

  getTimelineConnectorStyle = (status:string)=>{
    const connectorStyle={
      width :0,
      background:"#fff"
    }

    if(status === "pending" || status === "closed" || status === "on_hold"||status==="canceled"|| status === "refund" || status === "refund_rejected"){
      return {...connectorStyle,border:"1px dashed #8083A3"}

    }
    else{
      return {...connectorStyle,border: "1px dashed #00A7A2"}

    }
  }

  hideTimelineConnector=(status:string,index:number)=>{
    const {allMilestones} = this.state
    if(allMilestones.length===(index+1)&&(status === "canceled"|| status==="on_hold")){
      return false
    }
    else{
      return true
    }

  }

  getActivePeriod = (validationPeriod:string,coolingPeriod:string)=>{
    if(validationPeriod === "start" || coolingPeriod === "start"){
      return true
    }
    else{
      return false
    }

  }


  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    let { reason, reasons,updateStatus,showReasonValidation } = this.state
    // Customizable Area End
    return (
      // Customizable Area Start
    
        <Grid container className="resContainer">
          <Grid item sm={12} >
            {this.state.prjLoader ?
              <Box className={classes.centerLoader}>
                <CircularProgress size={24} style={{ color: 'grey' }} />
              </Box> :
              <>
                <ProjectHeaderDetails
                  resumeProject = {this.handleResumeProject}
                  lancerData={this.state.lancerData}
                  onProjectUpdate={this.handleProjectUpdateStatus}
                  onSendMessage={this.onSendMessage}
                  navigation={this.props.navigation}
                  classes={classes}
                  closureRequestStatus={this.state.closureRequestStatus} 
                  resumeDisputeProject = {this.handleResumeDiputedProject}
                  resumeLoader={this.state.resumeLoader}
                  />
                <Tabs
                  className={classes.filterBtnContainerStyle}
                  onChange={this.handleTabSelect}
                  data-testid="filteroptions"
                >
                    {tabsMenuList.map((tab, index: number) => {
                      return (
                        <Tab
                          className={this.TabStyles(index)}
                          label={tab.label}
                          key={tab.id}
                          value={tab.id}
                        />
                      );
                    })}
                 
                  
                </Tabs>
                {this.state.milestoneLoader ? (
                    <Box className={classes.loaderScedulingStyle}>
                      <CircularProgress size={24} style={{ color: 'grey' }} />
                    </Box>
                  ) :(
                    <> 
                {this.state.selectedTab === 0 && <Box className="resTopContainer" style={webStyle.topContainer}>
                  {this.state.allMilestones.length === 0 &&
                    <Typography className={classes.errorNoMilestone}>{configJSON.errorNoMilestones}</Typography>}
                  <Timeline>
                    {this.renderMilestones(classes)}
                  </Timeline>
                </Box>}
                </>
               )}
                {this.state.selectedTab === 1 && <ProjectDetailsPageStyles navigation={this.props.navigation} id={""} />}
                <Modal
                  open={this.state.showModal}
                >
                  <Grid sm={6}
                    className={classes.modalStyle}
                    style={{ position: 'absolute' }}
                    direction={"column"}
                  >
                    <Box className={classes.headingContainer}>
                      <Typography className={classes.modalHeading}>
                        {this.getModalHeading()}
                      </Typography>
                      <IconButton onClick={this.handleShowModal}><CancelOutlined fontSize="medium" /></IconButton>
                    </Box>
                    <Box p={1}>
                      {this.state.updateStatus === "closed" && (
                        <Box className={classes.notificationContainer}>
                          <Button className={classes.notification}>{configJSON.notification}</Button>
                        </Box>
                      )}
                      <Box className={classes.radioContainer}>
                        <FormControl fullWidth>
                          <Typography className={classes.reasonsHeading}>
                            {this.getOptionsLabel()}
                          </Typography>
                          <RadioGroup
                            value={this.state.option}
                            onChange={this.handleRadioChange}
                          >
                            <FormControlLabel value="1" control={<StyledRadioButton />} label={reasons[0]} className={classes.radioLabel} />
                            <FormControlLabel value="2" control={<StyledRadioButton />} label={reasons[1]} className={classes.radioLabel} />
                            <FormControlLabel value="3" control={<StyledRadioButton />} label={reasons[2]} className={classes.radioLabel} />
                            <FormControlLabel value="4" control={<StyledRadioButton />} label={reasons[3]} className={classes.radioLabel} data-test-id="label" />
                            <FormControlLabel value="5" checked={this.state.option === "5"} control={<StyledRadioButton />} label={"other"} className={classes.radioLabel} data-testid='five' />
                          </RadioGroup>
                          {this.state.option === "5" &&
                            <TextField
                              minRows={2}
                              multiline
                              variant="outlined"
                              onChange={(event) => this.handleReasonInput(event)}
                              value={reason}
                              InputProps={{
                                classes: {
                                  input: classes.placeholderStyle,
                                  root: classes.inputValueStyle,
                                },
                              }}
                              placeholder={configJSON.writeHere}
                              data-test-id='reasonInput'
                            />}
                            {showReasonValidation && (
                              <Typography variant="body1" style={{color:"rgb(235, 2, 10)",fontSize:"14px"}}>
                                Reason is required
                              </Typography>
                            )}                            

                        </FormControl>
                      </Box>
                      <Box className={classes.modalButtons}>
                        <Button
                          className={classes.onHoldBtnStyle}
                          onClick={this.handleShowModal}
                        >
                          {configJSON.cancelBtnTxt}
                        </Button>
                        <Button
                          className={classes.closeBtnSyle}
                          onClick={this.handleChangeProjectStatus}
                          data-test-id="sendRequest"
                          disabled={this.state.loading}
                        >
                          {this.handleButtonText(configJSON.sendBtnTxt)}
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Modal>

              </>}
          </Grid>
          <style>
            {`

                @media(max-width: 640px) {
                  .resCloseBtn, .resRevisionBtn, .resDownloadBtn {
                    padding: 5px 15px !important;
                    font-size: 12px !important;
                  }
                }
                @media(max-width: 480px) {
                  .resContainer {
                    display: block !important;
                  }
                  .resTopContainer {
                    padding: 10px !important;
                  }
                  .resTopContainer > ul {
                    padding: 5px 0 !important;
                  }
                  .resValidationStyle {
                    flex-direction: column;
                  }
                  .MuiTabs-flexContainer {
                    flex-wrap: wrap;
                  }
                  .resCloseBtn, .resRevisionBtn, .resDownloadBtn {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }
                @media(max-width: 399px) {
                  .resLancerContainer .MuiButton-text {
                    padding: 6px 15px !important;
                  }
                  .resDownloadGrid {
                    flex-direction: column !important;
                    gap: 0;
                    align-items: flex-start !important;
                  }
                  .resDownloadGrid .MuiButton-text {
                    padding: 6px 8px !important;
                  }
                }
                @media(max-width: 360px) {
                  
                  .resDisplayBox {
                    flex-direction: column !important;
                    align-items: flex-start !important;
                    margin: 0 !important;
                    gap: 5px;
                  }
                  
                }
                @media(max-width: 281px) {
                  .resDownloadItem {
                    display: block !important;
                  }
                }
            `}
          </style>
        </Grid>
      

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  topContainer: {
    borderRadius: 14,
    border: "1px solid #E4E6E8",
    padding: 25,
  },
  subTitleStyle: {
    color: "#8083A3",
    fontFamily: "Inter",
    fontSize: 12,
    fontWeight: 500,
  },
  onHoldBtnStyle: {
    borderRadius: 23,
    background: "#F2F2F2",
    color: "#222",
    textAlign: "center" as "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "capitalize" as "capitalize",
    padding: "5 35",
    "@media(max-width: 420px)": {
      width: "100%",
    }
  },
  closeBtnSyle: {
    borderRadius: 23,
    backgroundColor: "#00A7A2",
    color: "#fff",
    textAlign: "center" as "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 800,
    letterSpacing: "0.4px",
    textTransform: "capitalize" as "capitalize",
    padding: "5 35",
    "&:hover": {
      backgroundColor: "#00A7A2",
    },
    "@media(max-width: 420px)": {
      width: "100%",
    }
  },
  btnContainerStyle: {
    display: "flex",
    alignItems: "center",
    gap: 15,
    flexWrap: "wrap"
  } as const,
  sendContainerStyle: {
    display: "flex",
    justifyContent: "flex-end",
  },
  filterBtnContainerStyle: {
    margin: '30 0',
  },
  activeTabStyle: {
    background: "#00A7A2",
    color: "#fff",
    borderRadius: 20,
    fontFamily: "Inter",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 700,
    marginRight: 20,
    textTransform: "capitalize" as "capitalize",
  },
  tabStyle: {
    borderRadius: 20,
    background: "#fff",
    color: "#8083A3",
    fontFamily: "Inter",
    fontSize: 12,
    fontWeight: 700,
    marginRight: 20,
    textTransform: "capitalize" as "capitalize",
  },
  timelineDotStyle: {
    color: "#fff",
    fontFamily: "Inter",
    fontSize: 14,
    fontWeight: 700,
    height: 20,
    width: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  timelinecontainer: {},
  mileStoneTitleclassName: {
    color: "#222",
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: 700,
  },
  timelineConectorStyle: {
    width: "0",
    backgroundColor: "#fff",
  },
  timelineConectorPendingStyle: {
    border: "1px dashed #d90000",
    width: "0",
    backgroundColor: "#fff",
  },
  displayBox: {
    display: "flex",
    alignItems: "center",
    margin: "8px 0",
  },
  timelineContentHeader: {

    fontSize: "16px",
    fontWeight: 700,
    marginRight: "0.7vw",
    fontFamily: "Inter"
  },
  displayGrid: {
    display: "flex",
    alignItems: "center",
    padding: "2vw",
    borderRadius: "10px",
    backgroundColor: "rgba(248,248,251,0.7)",
    opacity: 0.7,
  },
  timelineContentDesc: {
    color: "#8083a3",
    fontSize: "14px",
    fontWeight: 500,
  },
  timelineContentDesc1: {
    color: "#8083a3",
    fontSize: "14px",
    fontWeight: 500,
    whiteSpace: 'pre-wrap' as const,
    wordBreak: 'break-word' as const,
  },
  divider: {
    margin: "2vw 0",
    width: "100%",
  },
  divider1: {
    margin: "1vw 0",
    width: "100%",
  },
  validationStyle:{
    width:'100%',
    display:'flex',
    justifyContent:'flex-start',

    },
  displayVerifyBox: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "8px",
  } as const,
  milestoneGridImage: {
    width: "20px",
    height: "21px",
    marginRight: "1vw",
  },
  milestoneGridImage1: {
    width: "20px",
    marginRight: "1vw",
    height: "21px",
  },
  timelineContentVerifyBtn: {
    color: "#fff",
    fontSize: "10px",
    fontWeight: 700,
    width: "63px",
    height: "24px",
    borderRadius: "12px",
    backgroundColor: " #16a700",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none" as const,
  },
  timelineContentVerifyBtn1: {
    color: "#fff",
    fontSize: "10px",
    fontWeight: 700,
    width: "63px",
    height: "24px",
    borderRadius: "12px",
    backgroundColor: " #16a700",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none" as const,
  },
  timelineContentWorkReview: {
    color: "#222222",
    fontSize: "14px",
    fontWeight: 700,
  },
  projectDetailsBox: {
    display: "flex",
    alignItems: "center",
    columnGap: '8px'
  },
  fileDetailsBlockStyle: {
    borderRadius: 10,
    border: "1px solid #E4E6E8",
    background: "#F9F9F9",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px",
    // width: "60vw",
  },
  fileIconStyle: {
    width: 26,
    height: 26,
  },
  fileNameStyle: {
    color: "#8083A3",
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: 600,
  },
  fileSizeStyle: {
    color: "#8083A3",
    fontSize: "10px",
    fontFamily: "Inter",
    fontWeight: 600,
  },
  fileSizeStyle1: {
    color: "#8083A3",
    fontSize: "10px",
    fontFamily: "Inter",
    fontWeight: 600,
    marginLeft: "4px",
  },
  deleteIconStyle: {
    borderRadius: "50%",
    border: "1px solid rgba(228, 230, 232, 0.60)",
    background: "#FFF",
    padding: 10,
    cursor: 'pointer',
  },
  deleteIconStyle1: {
    borderRadius: "50%",
    border: "1px solid rgba(228, 230, 232, 0.60)",
    background: "#FFF",
    padding: 8,
    cursor: 'pointer',
  },
  reviewFilesLabel: {
    color: "#222222",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 700,
  },
  reviewSubmitFiles: {
    display: "flex",
    alignItems: "center",
    columnGap: 10,
    marginBottom: 20,
  },
  filesDescription: {
    color: "#8083A3",
    fontSize: "10px",
    fontFamily: "Inter",
    fontWeight: 400,
    textAlign: "right" as "right",
    marginTop: 15,
  },
  dueDateStyle: {
    marginRight: "2vw",
  },
  paymentStyle: {
    marginRight: "1vw",
    display:"flex",
    alignItems:'center'
  },
  paymentContainer: {
    borderRadius: 10,
    border: "1px solid #E4E6E8",
    background: "#FFF",
    padding: 20,
  },
  requestDetailsContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    width: "100%",
  },
  paymentStatus: {
    color: "#8083A3",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 500,
  },
  paymentValue: {
    color: "#222",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 500,
    display:"flex",
    alignItems:'center'
  },
  revesionDeadlineTxtStyle: {
    color: "#8083A3",
    fontSize: "10px",
    fontFamily: "Inter",
    fontWeight: 500,
  },
  learnmoreTxtStyle: {
    color: "#00A7A2",
    fontSize: "10px",
    fontFamily: "Inter",
    fontWeight: 700,
    textDecorationLine: "underline",
    marginLeft: 15
  },
  timelineContentPendingBtn: {
    color: "#fff",
    fontSize: "10px",
    fontWeight: 700,
    height: "24px",
    borderRadius: "12px",
    backgroundColor: "#F7AD00",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none" as const,
  },
  addNewMilestoneBtn: {
    color: "#00A7A2",
    border: "2px solid #00A7A2",
  },
  requestDetailsStyle: {
    color: "#8083A3",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 400,
  },
  requestDetailsDescriptionStyle: {
    color: "#8083A3",
    fontSize: "14px",
    fontFamily: "Inter",
    marginBottom: 65,
    fontWeight: 700,
  },
  dropZoneStyle: {
    borderRadius: "10px",
    border: "1.5px dashed #00A7A2",
    background:
      "linear-gradient(0deg, rgba(0, 167, 162, 0.04) 0%, rgba(0, 167, 162, 0.04) 100%), #FFF",
    padding: "20px",
    margin: "15px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column" as "column",
    rowGap: 5,
    cursor: "pointer",
  },
  downloadIconStyle: {
    padding: 10,
    borderRadius: "50%",
    background: "rgba(0, 167, 162, 0.06)",
    width: 36,
    height: 36,
  },
  uploadFileTxt: {
    color: "#fff",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 700,
    borderRadius: "20px",
    height: '18px',
    width: '80px',
    padding: '8px 14px',
    backgroundColor: "#00A7A2",
  },
  maxFileSizeTxt: {
    color: "#8083A3",
    fontSize: "10px",
    fontFamily: "Inter",
    fontWeight: 500,
  },
  formControlStyle: {
    margin: "15px 0px",
  },
  labelStyle: {
    color: "#8083A3",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 400,
  },
  placeholderStyle: {
    "&::placeholder": {
      color: "#8083A3",
      fontSize: "14px",
      fontFamily: "Inter",
      fontWeight: 700,
    },
  },
  inputValueStyle: {
    color: "#222",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 700,
  },
  payBtnContainer: {
    justifyContent: "flex-end",
    display: "flex",
    width: "100%",
  },
  profileHeading: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "28px",
    color: "#222222",
    margin: "40px 0px 26px 0px",
  },
  conatiner_a: {
    padding: "15px",
  },
  sideRoutingBlock: {
    padding: "20px",
    borderRadius: "14px",
    border: "1px solid rgba(228, 230, 232, 0.60)",
    backgroundColor: "rgba(242, 242, 242, 1)",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    width: "218px",
    height: "fit-content",
    marginBottom: "20px"
  },
  centerLoader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  infoIconStyle: {
    height: '18px',
    width: '18px',
    marginRight: '1vw',
    color: '#000'
  },
  downloadGrid: {
    display: 'flex',
    alignItems: 'center',
    color: "#00A7A2",
    columnGap: '15px',
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 500,
    textDecoration: 'underline',
    textTransform:"capitalize",
    padding: "0"
  } as const,
  downloadImg: {
    width: '26px',
    height: '26px',
    marginRight: '1vw',
    cursor: 'pointer',
  },
  downloadItemBox: {
    display: 'flex',
    alignItems: 'center',

  },
  downloadfileName: {
    color: '#222222',
    fontSize: '12px',
    fontWeight: 600,
    marginRight: '0.5vw',
  },
  downloadfileSize: {
    color: 'rgba(128,131,163,0.76)',
    fontSize: '10px',
    fontWeight: 500,
  },
  downloadItemBorder: {
    marginBottom: '1vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1vw',
    borderRadius: '10px',
    border: '1px solid #e4e6e8',
    backgroundColor: '#ffffff',

  },
  documentsGrid: {
    display: 'flex',
    alignItems: 'center',
  },
  filesBox: {
    width: '100%'
  },
  iconsBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '0 0.5vw'
  },
  errorNoDetails: {
    color: '#222',
    padding: "2vw",
    fontSize: '16px',
    fontWeight: 500,
  },
  modalStyle: {
    display: "flex",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: '0px 3px 4px rgba(153,155,168,0.25)',
    background: '#ffffff',
    borderRadius: '16px',
  },
  headingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #8083a3',
    padding: '0.5vw 0 0 1vw',
  },
  modalHeading: {
    color: '#222222',
    fontSize: '20px',
    fontWeight: 700,
    fontFamily: 'inter',
  },
  notification: {
    fontSize: '10px',
    border: '1px solid #8083a3',
    color: '#8083a3',
  },
  reasonsHeading: {
    color: '#222222',
    fontSize: '16px',
    fontWeight: 700,
    fontFamily: 'inter',
  },
  radioLabel: {
    color: '#8083a3',
    fontSize: '10px',
    fontWeight: 400,
    fontFamily: 'Inter',
    marginBottom: '0',
  },
  notificationContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1vw 0',
  },
  errorNoMilestone: {
    color: '#222',
    padding: "2vw",
    fontSize: '16px',
    fontWeight: 500,
  },
  modalButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '1vw',
    gap:"10px"
  },
  radioContainer: {
    paddingTop: '1vw',
    paddingLeft: "1vw",
  },

  backButtonStyle: {
    borderRadius: 23,
    background: "#F2F2F2",
    color: "#222",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: 14,
    padding: "10 30",
    marginLeft: 10,
  },
  iconBtnStyle: {
    marginRight: 10,
    fontSize: 16,
  },
  titleStyle: {
    color: "#222222",
    fontFamily: "Inter",
    fontSize: 20,
    fontWeight: 700,
  },
  titleBlockStyle: {
    display: "flex",
    columnGap: 10,
    alignItems: "center",
  },
  userNameStyle: {
    color: "#222",
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: 700,
  },
  backspaceIcon: {
    paddingRight: '1vw',
    color: "#222",
    // fontSize: 18,
    fontWeight: 700,
    cursor: 'pointer'
  },
  subDetailsBlock: {
    display: "flex",
    alignItems: "center",
    columnGap: 10,
  },
  cardStyle: {
    display: "flex",
    alignItems: "start",
    columnGap: 20,
    marginTop: 20,
  },
  iconsContainer: {
    display: "flex",
    alignItems: "center",
    columnGap: 20,
  },
  iconStyle: {
    fontSize: 14,
    color: "#8083A3",
  },
  settingsIconStyle: {
    color: "#8083A3",
  },
  dividerStyle: {
    margin: "20px 0 15px 0"
  },
  marginStyle:{
    marginRight: "9px"
  },
  loaderScedulingStyle:{ 
    display: 'flex',
     alignItems: 'center', 
     justifyContent: 'center',
      width: '100%'
     },
     currencyIconStyle:{
      height:'16px',
      width:'16px',
      color:'#000'
     },
     sponsorCurrencyColor:{
      color:"rgb(0, 167, 162)" ,
       fontWeight:600,
       fontSize: "13px",
      },
      tabMenu: {
        display: "flex",
        flexWrap: "wrap" as const
      }
};

export const SchedulingPageStyles = withStyles(webStyle)(Scheduling);
// Customizable Area 